import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import type { ReportTemplate, CreateReportTemplateRequest } from '@reports'

export const fetchApiReportTemplates = (divisionId: string) =>
  fetchAPI<ReportTemplate[]>(`/companies/${divisionId}/reports/templates`)

export const useApiReportTemplates = () => {
  const divisionId = getCurrentDivisionId()
  return useQuery({
    queryKey: ['getTemplates', divisionId],
    queryFn: () => fetchApiReportTemplates(divisionId),
    staleTime: 10 * 1000, // 10s
    gcTime: 24 * 60 * 60 * 1000, // 24h
  })
}

export const useApiSaveReportTemplate = (divisionId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body: CreateReportTemplateRequest) =>
      fetchAPI<ReportTemplate>(`/companies/${divisionId}/reports/templates/`, {
        method: 'POST',
        body,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['getTemplates', divisionId],
      })
    },
  })
}

export const useApiDeleteReportTemplate = (divisionId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (templateId: string) =>
      fetchAPI<ReportTemplate>(
        `/companies/${divisionId}/reports/templates/${templateId}`,
        {
          method: 'DELETE',
        },
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['getTemplates', divisionId],
      })
    },
  })
}
